import { render, staticRenderFns } from "./stack-forms-diff.vue?vue&type=template&id=343d4122&scoped=true&"
import script from "./stack-forms-diff.vue?vue&type=script&lang=js&"
export * from "./stack-forms-diff.vue?vue&type=script&lang=js&"
import style0 from "jsondiffpatch/dist/formatters-styles/html.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./stack-forms-diff.vue?vue&type=style&index=1&id=343d4122&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "343d4122",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VSwitch})
